<template>
  <div class="screenshot d-flex flex-column mt-1">
    <div
      class="time-indicator w-100"
      :style="`background:${
        sectionData.data ? '#387b46' : 'transparent'
      };height:24px`"
      :class="{
        'rounded-left': !sectionData.hasPrevious,
        'rounded-right': !sectionData.hasNext,
      }"
    >
      <div
        v-if="sectionData.data && !sectionData.hasPrevious"
        class="d-flex h-100 text-white ml-1 align-items-center"
      >
        {{ (sectionData.totalNext + 1) * 10 }}
        {{ $t("student-diary-module.minutes") }}
      </div>
    </div>
    <div class="screenshot-image w-100">
      <a
        v-if="sectionData.data"
        target="_"
        class="w-100 h-100"
      >
        <img
          :src="sectionData.data.screenshot"
          alt=""
          @click="openImage(sectionData.data.screenshot)"
        >
      </a>
      <div
        v-else
        class="
          w-100
          no-screenshot
          h-100
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <p>
          {{ $t("student-diary-module.idle-time") }}
        </p>
      </div>
    </div>
    <div
      v-if="sectionData.data"
      class="stats w-100"
    >
      <div class="d-flex align-items-center stats-item">
        <span class="stats-title">{{ $t('report.activity') }}</span>
        <div class="w-100">
          <p class="m-0 p-0">
            {{ sectionData.data.avg_activity }}
          </p>
          <!-- <div
            class="progress w-100"
            style="height: 10px; color: rgb(229, 71, 50)"
          >
            <div
              role="progressbar"
              :style="`background-color:${getStatusColor(
                sectionData.data.avg_activity
              )};width:${sectionData.data.avg_activity}%`"
              class="progress-bar"
            />
          </div> -->
        </div>
      </div>
      <div class="d-flex align-items-center stats-item">
        <span class="stats-title">{{ $t('report.attention') }}</span>
        <div
          class="progress w-100"
          style="height: 10px; color: rgb(229, 71, 50)"
        >
          <div
            role="progressbar"
            :style="`background-color:${getStatusColor(
              sectionData.data.avg_attention
            )};width:${sectionData.data.avg_attention}%`"
            class="progress-bar"
          />
        </div>
      </div>
      <div
        v-if="showLangLevel"
        class="d-flex align-items-center stats-item"
      >
        <span class="stats-title">{{ $t('report.level') }}</span>
        <div>
          <!-- size -->
          <b-button-group size="sm">
            <template v-if="distLangLevel">
              <div
                v-for="distlang,distIndex in distLangLevel"
                :key="distIndex"
                type="button"
                class="btn btn-outline-primary"
                :class="{ active: getLevelFromScore(sectionData.data.cerf_level) === distIndex }"
              >
                {{ distLangLevel[distIndex].label }}
              </div>
            </template>
            <template v-else>
              <div
                v-for="lang, langIndex in cefrLangLevel"
                :key="langIndex"
                type="button"
                class="btn btn-outline-primary"
                :class="{ active: getLevelFromScore(sectionData.data.cerf_level) === langIndex }"
              >
                {{ lang.label }}
              </div>
            </template>
          </b-button-group>
        </div>
      </div>
      <div class="d-flex align-items-center stats-item">
        <span class="stats-title">{{ $t('report.score') }}</span>
        <p class="m-0 p-0">
          {{ sectionData.data.avg_score }}%
        </p>
      </div>
    </div>
    <div
      v-else
      style="width: 214.94px"
    />
    <FsLightbox
      :toggler="toggler"
      :sources="screenCasts"
      :slide="slide"
      :types="[...new Array(screenCasts.length).fill('image')]"
    />
  </div>
</template>

<script>
import { BButtonGroup } from 'bootstrap-vue'
import { getStudentProgressBarColor } from '@/utils/colors'
import { getLevelFromScore, cefrLangLevel } from '@/const/langLevel'
import FsLightbox from 'fslightbox-vue'
import { mapGetters } from 'vuex'
import { checkSettings } from '@/utils/visibilitySettings'

export default {
  components: {
    BButtonGroup,
    FsLightbox,
  },
  props: {
    sectionData: {
      type: Object,
      default: null,
    },
    screenCasts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      toggler: false,
      slide: 1,
      cefrLangLevel,
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),

    showLangLevel() {
      const distLangLevelSetting = this.getDistrictSettings?.find(metaValue => metaValue.key === 'lang_level') ?? '0'
      // check school level setting
      const schoolLangLevelSetting = this.getSchoolSettings?.find(metaValue => metaValue.key === 'lang_level') ?? '0'
      if (distLangLevelSetting?.value !== '0' && schoolLangLevelSetting?.value !== '0') {
        return false
      }
      return true
    },
    distLangLevel() {
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distLangLevelSetting = checkSettings('lang_level_indicator', schoolSetting, distSetting)?.value
      return JSON.parse(distLangLevelSetting)
    },
  },
  methods: {
    getLevelFromScore(level) {
      return getLevelFromScore(level)
    },
    getStatusColor(value) {
      return getStudentProgressBarColor(value)
    },
    openImage(image) {
      // this.sources = this.
      this.slide = this.screenCasts.indexOf(image) + 1
      this.toggler = !this.toggler
    },
  },
}
</script>

<style lang="css" scoped>
.day {
  overflow: auto;
}
.time {
  margin-top: 100px;
  margin-right: 16px;
}
.screenshot-image {
  height: 150px;
  border-radius: 8px;
  padding: 2px;
  width: 160px;
}
.screenshot-image img,
.no-screenshot {
  border-radius: 8px;
  border: 1px solid black;
  object-fit: cover;
}
.screenshot {
  flex: 1 1 0px;
  flex-shrink: none;
}
.stats {
  padding: 8px 2px;
}
.stats-title {
  margin-right: 8px;
  font-size: 12px;
  min-width: 60px !important;
}
.btn-group-sm > .btn {
  padding: 4px 10px !important;
}
.stats-item {
  margin: 4px 0;
}
.screenshot-image img{
  width: 100%; height: 100%; object-fit: cover; object-position: 50% 0%;
}
</style>
